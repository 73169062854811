.header-wrapper {
  width: 100%;
  padding-top: 20px;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 70px;
  // padding-bottom: 80px;

  .header-content {
    max-width: 780px;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .image {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.25em;
    margin-right: 24px;
  }

  .service-status {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  .subscribe-wrapper {
    margin-left: 20px;
  }

  .flex {
    display: flex;
  }
}

.header-wrapper {
  &.empty-state {
    height: 143px;
  }
}

a {
  &.go-to {
    display: flex;
    align-items: center;
    color: #FFF;
    animation: opacity 0.25s ease;
    font-size: 14px;
    margin-bottom: 3px;
    text-decoration: none;

    svg {
      margin-right: 5px;
      margin-top: 1px;
    }

    &:hover {
      cursor: pointer;
      opacity: .8;
    }
  }
}