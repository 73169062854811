.stat-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  // margin-bottom: 80px;

  .header {
    width: 100%;
    
    &.separate {
      margin-bottom: 15px;
    }

    .key {
      font-size: 15px;
    }
    
    .value {
      font-size: 17px;
    }

    .key,
    .value {
      font-weight: 500;
    }
  }

  .left {
    width: 85%;
    display: inline-flex;
  }

  .right {
    width: 15%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.tooltip {
  background-color: #0b0a33;
  opacity: .85;
  padding: 10px;
  border-radius: 5px;
  color: white;

  .header {
    line-height: 1.5em;
  }

  .value {
    font-size: 14px;
  }
}
