#root {
  min-height: 100vh;
}

.container {
  margin-left: 25%;
  margin-right: 25%;
}

@media screen and (max-width: 991px) {
  .container {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (max-width: 690px) {
  .container {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.no-pointer-cursor {
  cursor: initial;
}

.history-page {
  .whitebox {
    &.whitebox-incident {
      &:hover {
        box-shadow: rgba(63, 63, 68, 0.15) 0 0 0 1px, rgba(63, 63, 68, 0.25) 0 1px 3px 0;
        cursor: pointer;
      }
    }
  }
}

.whitebox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 780px;
  margin: auto;
  padding: 20px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 3px;
  box-shadow: rgba(63,63,68,.05) 0 0 0 1px, rgba(63,63,68,.15) 0 1px 3px 0;

  &.whitebox-incident {
    position: relative;
    align-items: flex-start;
  }

  &.no-padding {
    padding: 0px;
  }
}

.separator {
  margin-bottom: 25px;
  margin-top: 25px;
  border-bottom: 1px solid #efefef;

  display: flex;
  width: 100%;
}

.status-row {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  flex-direction: column;

  &.first {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &.last {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 0;
  }

  border-bottom: 1px solid #efefef;

  .left {
    line-height: 24px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 17px;

    .status-type {
      line-height: 10px;
      font-size: 12px;
      font-weight: 600;

      &.operational {
        color: #08b374;
      }

      &.service-issue {
        color: #e4951b;
      }
      
      &.maintenance {
        color: #1046c3;
      }
      
      &.service-outage {
        color: #ff4c14;
      }
    }
  }

  .right {
    line-height: 29px;
  }

  img, svg {
    vertical-align: middle;
    margin-bottom: -0.35em;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.right-wrapper {
  display: flex; 
  justify-content: flex-end;
  max-width: 780px;
  margin: auto;
  padding-right: 10px;
  padding-left: 10px;

  &.no-padding {
    padding: 0;
  }
}

.selector-item {
  font-size: 13px;
  margin-bottom: 10px;
  margin-left: 3px;
  margin-top: -20px;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }

  &.selected {
    color: black;
    background-color: #e6e6e6;
    font-weight: 600;
  }
}

.powered {
  // color: #929caf;
  font-size: 13px;
  padding-bottom: 40px;
  font-weight: 500;

  a {
    color: black;
    text-decoration: none;
  }
}

.by {
  cursor: pointer;
  color: #3657ff;
  font-weight: 500;
  text-decoration: none;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 4em;
}

.full-width {
  width: 100%;
}

@media screen and (max-width: 490px) {
  .go-to {
    display: none !important;
  }
}

@media screen and (max-width: 810px) {
  .whitebox {
    margin-right: 10px;
    margin-left: 10px;
  }

  .incidents-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.load-spinner {
  margin: auto;
  font-size: 10px;
  color: transparent;
  height: 18px;
  width: 18px;
  border: 2px solid #0069FF;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  box-sizing: border-box;
}

.status-row-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.sub-status {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 1em;
  padding-top: 10px;
}

.group-collapsible {
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-bottom: initial;
    margin-right: .25em;
    opacity: 0.65;
  }
}
