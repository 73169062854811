html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
  background-color: #FAFBFC;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  outline: none;
}
