.incidents-container {
  margin: auto;
  max-width: 780px;
  margin-top: 40px;

  .whitebox {
    &.whitebox-incident {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .separator {
    margin-bottom: 30px;
    margin-top: -10px;
    margin-left: 22%;
    width: 78%;
    flex-direction: column;
    border-bottom: 1px solid #d9d9d9;
  }

  .incidents-title {
    opacity: .6;
    margin-bottom: 30px;
  }

  .date {
    width: 22%;
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    padding-right: 10px;
  }

  .incident {
    width: 78%;
    display: inline-block;

    .title {
      font-weight: 700;
      font-size: 17px;
      margin-bottom: .5em;
      display: flex;
      align-items: center;
    }

    .update {
      font-size: 15px;
      margin-bottom: 1.5em;

      .time {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        display: inline-block;

        .ago {
          font-size: 13px;
          opacity: .6;
          font-weight: 400;
          margin-left: 6px;
        }
      }

      .text {
        line-height: 1.5em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .estimated-duration {
    font-size: 14px;
    opacity: .6;
    font-weight: 400;
    margin-top: -.5em;
    margin-bottom: 1em;
  }

  .incident-type {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }

  .incident-type-img {
    width: 13px;
    height: 20px;
    margin-right: 6px;
    vertical-align: middle;
    margin-bottom: 0.15em;
  }

  .update-status {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    left: 20px;
    width: calc(22% + 20px);
    margin-top: 3px;
    color: #6f7782;
  }
}

.incident-link {
  text-decoration: none;
  color: initial;
}

.whiteless-box {
  position: relative;

  .update-status {
    left: 0;
  }
}

.text-cta, .read-more {
  text-decoration: none;

  .arrow, .arrow-right {
    display: inline-block;
    transition: all 200ms ease;
  }

  &:hover {
    cursor: pointer;

    .arrow {
      transform: translateX(-2px);
    }
    
    .arrow-right {
      transform: translateX(2px);
    }
  }
}

.float-right {
  float: right;
}

@media screen and (max-width: 790px) {
  .whitebox-incident {
    padding: 15px;

    .update-status {
      left: 15px;
    }

    .date {
      width: 30%;
    }

    .incident {
      width: 70%;
    }
  }
}

a {
  &.nav-link-black {
    font-size: 16px;
    font-weight: 500;
    color: rgb(26, 31, 54);
    text-decoration: none;
  }
}

.affected-components {
  margin-top: 1.5em;
  margin-left: 22%;
  font-size: 13px;

  .affected-component {
    background-color: rgb(240, 242, 247);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    padding: 2px 8px;
    margin-right: 8px;
    border-radius: 3px;
  }
}

.update-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #6f7782;
  font-weight: 500;
  margin-top: .25em;
}